<template>
  <div class="artist-table-component">
    <table-title>
      <p>
        藝術家列表: <span class="unit">數量：{{ total }}</span>
      </p>

      <el-button type="success" @click="dialogVisible.create = true"
        >新增藝術家</el-button
      >
    </table-title>

    <el-table :data="artists">
      <el-table-column label="啟用" width="80" sortable>
        <template v-slot="{ row }">
          <el-checkbox
            :value="row.active"
            @change="handleActive(row.artist_id)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="大頭貼" prop="avatar" width="150" sortable>
        <template v-slot="{ row }">
          <el-avatar
            :size="60"
            fit="cover"
            style="object-position: center center"
            :src="handleAvatarUrl(row.avatar)"
          >
          </el-avatar>
        </template>
      </el-table-column>
      <el-table-column label="排序" sortable>
        <template v-slot="{ $index, row }">
          <el-input
            v-model.number="artistsOrder[$index].order"
            style="width: 60px; margin-right: 4px"
            size="mini"
          />
          <el-button
            type="primary"
            size="mini"
            @click="
              handleUpdateOrder(row.artist_id, artistsOrder[$index].order)
            "
            >儲存</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="中文姓名"
        prop="artist_zh.name"
        sortable
      ></el-table-column>
      <el-table-column
        label="英文姓名"
        prop="artist_en.name"
        sortable
      ></el-table-column>
      <el-table-column label="中文簡歷" prop="artist_zh.introduction" sortable>
        <template v-slot="{ row }">
          <div class="text-overflow">
            {{ row.artist_zh.introduction }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="編輯" fixed="right" width="100">
        <template v-slot="{ row }">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(row.artist_id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div v-if="dialogVisible.create">
      <create-artist-dialog
        :dialog-visible="dialogVisible.create"
        @close:dialog="handleClose"
      ></create-artist-dialog>
    </div>
  </div>
</template>

<script>
import CreateArtistDialog from "@/views/group/main/components/CreateArtistDialog";
import { deleteArtist, updateGroupArtistOrder } from "@/api/group";
import { updateArtistActive } from "@/api/artist";

export default {
  name: "ArtistTable",
  components: { CreateArtistDialog },
  props: {
    artists: {
      required: true,
      type: Array,
      default: () => [],
    },
    artistsOrder: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: {
        create: false,
      },
    };
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
    total() {
      return this.artists.length;
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible.create = false;
      this.$emit("get:group");
    },
    handleAvatarUrl(url) {
      return `${process.env.VUE_APP_IMAGE_API}/${url}`;
    },
    async handleActive(artistId) {
      const index = this.artists.findIndex(
        ({ artist_id }) => artist_id === artistId
      );

      if (index !== -1) {
        await updateArtistActive(artistId, {
          active: !this.artists[index].active,
        });
        this.$emit("update:active", { artistId });

        this.$message({
          type: "success",
          message: "更新成功",
        });
      } else {
        this.$message({
          type: "error",
          message: "更新失敗",
        });
      }
    },
    async handleUpdateOrder(artist_id, order) {
      try {
        await updateGroupArtistOrder(this.groupId, artist_id, { order });

        this.$message({
          type: "success",
          message: "編輯成功",
        });
      } catch (e) {
        this.$message({
          type: "error",
          message: "編輯失敗",
        });
      }
    },
    handleDelete(artist_id) {
      this.$confirm("是否刪除該藝術家", "警告", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteArtist(this.groupId, artist_id);

          this.$message({
            type: "success",
            message: "刪除成功",
          });

          this.$emit("get:group");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "刪除取消",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
