<template>
  <el-dialog
    title="新增藝術家"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form
      ref="artistForm"
      :model="artist_form"
      :rules="artist_rules"
      label-position="top"
    >
      <el-form-item prop="artist_id" label="藝術家">
        <el-select
          v-model="artist_form.artist_id"
          class="w-100"
          filterable
          remote
          :remote-method="handleSearchArtists"
        >
          <el-option
            v-for="{ artist_id, artist_en, artist_zh } in artists"
            :key="artist_id"
            :value="artist_id"
            :label="artist_zh.name"
          >
            <div style="float: left; width: 400px" class="text-overflow">
              {{ artist_zh.name }} - {{ artist_zh.introduction }}
            </div>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              artist_en.name
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleClose">取消</el-button>
      <el-button type="success" @click="handleSubmit">送出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getArtists } from "@/api/artist";
import { addArtist } from "@/api/group";

export default {
  name: "CreateArtistDialog",
  props: {
    dialogVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      artist_form: {
        artist_id: "",
      },
      artist_rules: {
        artist_id: [
          { required: true, message: "請選擇藝術家", trigger: "blur" },
        ],
      },
      setup: {
        search: "",
      },
      artists: [],
    };
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetArtists();
  },
  methods: {
    async handleGetArtists() {
      this.artists = await getArtists(this.setup);
    },
    handleSearchArtists(query) {
      this.setup.search = query;
      this.handleGetArtists();
    },
    handleClose() {
      this.$emit("close:dialog");
    },
    handleSubmit() {
      this.$refs.artistForm.validate(async (valid) => {
        if (valid) {
          await addArtist(this.groupId, this.artist_form.artist_id);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          this.handleClose();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
