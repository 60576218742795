var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"artist-table-component"},[_c('table-title',[_c('p',[_vm._v(" 藝術家列表: "),_c('span',{staticClass:"unit"},[_vm._v("數量："+_vm._s(_vm.total))])]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){_vm.dialogVisible.create = true}}},[_vm._v("新增藝術家")])],1),_c('el-table',{attrs:{"data":_vm.artists}},[_c('el-table-column',{attrs:{"label":"啟用","width":"80","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{attrs:{"value":row.active},on:{"change":function($event){return _vm.handleActive(row.artist_id)}}})]}}])}),_c('el-table-column',{attrs:{"label":"大頭貼","prop":"avatar","width":"150","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-avatar',{staticStyle:{"object-position":"center center"},attrs:{"size":60,"fit":"cover","src":_vm.handleAvatarUrl(row.avatar)}})]}}])}),_c('el-table-column',{attrs:{"label":"排序","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return [_c('el-input',{staticStyle:{"width":"60px","margin-right":"4px"},attrs:{"size":"mini"},model:{value:(_vm.artistsOrder[$index].order),callback:function ($$v) {_vm.$set(_vm.artistsOrder[$index], "order", _vm._n($$v))},expression:"artistsOrder[$index].order"}}),_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.handleUpdateOrder(row.artist_id, _vm.artistsOrder[$index].order)}}},[_vm._v("儲存")])]}}])}),_c('el-table-column',{attrs:{"label":"中文姓名","prop":"artist_zh.name","sortable":""}}),_c('el-table-column',{attrs:{"label":"英文姓名","prop":"artist_en.name","sortable":""}}),_c('el-table-column',{attrs:{"label":"中文簡歷","prop":"artist_zh.introduction","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(row.artist_zh.introduction)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"編輯","fixed":"right","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini","type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.handleDelete(row.artist_id)}}},[_vm._v("刪除")])]}}])})],1),(_vm.dialogVisible.create)?_c('div',[_c('create-artist-dialog',{attrs:{"dialog-visible":_vm.dialogVisible.create},on:{"close:dialog":_vm.handleClose}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }