<template>
  <el-dialog
    title="新增團隊作品"
    :visible.sync="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form
      ref="workForm"
      :model="work_form"
      :rules="work_rules"
      label-position="top"
    >
      <el-form-item prop="work_id" label="作品">
        <el-select
          v-model="work_form.work_id"
          class="w-100"
          filterable
          remote
          :remote-method="handleSearchWork"
        >
          <el-option
            v-for="{ work_id, work_en, work_zh } in works"
            :key="work_id"
            :value="work_id"
            :label="work_zh.title"
          >
            <div style="float: left; width: 400px" class="text-overflow">
              {{ work_zh.title }} - {{ work_zh.description }}
            </div>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              work_en.title
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="handleClose">取消</el-button>
      <el-button type="success" @click="handleSubmit">送出</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getWorks } from "@/api/work";
import { addGroupWork } from "@/api/group";

export default {
  name: "CreateWorkDialog",
  props: {
    dialogVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      work_form: {
        work_id: "",
      },
      work_rules: {
        work_id: [{ required: true, message: "請選擇作品", trigger: "change" }],
      },
      setup: {
        search: "",
      },
      works: [],
    };
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetWorks();
  },
  methods: {
    async handleGetWorks() {
      this.works = await getWorks(this.setup);
    },
    handleSearchWork(query) {
      this.setup.search = query;

      this.handleGetWorks();
    },
    handleClose() {
      this.$emit("close:dialog", "create");
    },
    handleSubmit() {
      this.$refs.workForm.validate(async (valid) => {
        if (valid) {
          await addGroupWork(this.groupId, this.work_form.work_id);

          this.$message({
            type: "success",
            message: "創建成功",
          });

          this.handleClose();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
