<template>
  <div class="detail-group-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Group' }">團隊管理</el-breadcrumb-item>
      <el-breadcrumb-item>團隊內容</el-breadcrumb-item>
    </menu-breadcrumb>

    <div class="container">
      <div class="flex-end" style="padding: 12px 0">
        <el-button type="info" @click="show_detail = !show_detail"
          >{{ !show_detail ? "內容展開" : "內容收起" }}
        </el-button>
      </div>
      <div
        class="content text-center"
        v-if="!show_detail"
        @click="show_detail = !show_detail"
      >
        內容過多，請點擊展開
      </div>
      <el-form
        label-position="top"
        prop="photo"
        ref="groupForm"
        :model="group_form"
        v-show="show_detail"
      >
        <el-form-item label="團隊照片" prop="photo">
          <el-upload
            class="photo-uploader"
            action=""
            :show-file-list="false"
            accept=".jpeg,.jpg,.png"
            disabled
          >
            <img
              v-if="preview_url"
              :src="preview_url"
              class="photo"
              alt="photo"
            />
            <i v-else class="el-icon-plus photo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="中文名稱" prop="name_zh">
          <el-input v-model="group_form.name_zh" disabled></el-input>
        </el-form-item>
        <el-form-item label="英文名稱" prop="name_en">
          <el-input v-model="group_form.name_en" disabled></el-input>
        </el-form-item>
        <el-form-item label="中文介紹" prop="description_zh">
          <el-input
            type="textarea"
            v-model="group_form.description_zh"
            :rows="5"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="英文介紹" prop="description_en">
          <el-input
            type="textarea"
            v-model="group_form.description_en"
            :rows="5"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect">回主頁</el-button>
            <el-button
              type="primary"
              @click="$router.push({ name: 'EditGroup', params: groupId })"
              >編輯
            </el-button>
          </div>
        </el-form-item>
      </el-form>

      <artist-table
        :artists="artists"
        :artists-order="group_artists"
        @get:group="handleGetGroup"
        @update:active="handleGetGroup"
      ></artist-table>

      <category-table
        :categories="categories"
        @get:group="handleGetGroup"
      ></category-table>

      <work-table :works="works" @get:group="handleGetGroup"></work-table>
    </div>
  </div>
</template>

<script>
import { getGroup } from "@/api/group";
import ArtistTable from "@/views/group/main/components/ArtistTable";
import WorkTable from "@/views/group/main/components/WorkTable";
import CategoryTable from "@/views/group/main/components/CategoryTable";

export default {
  name: "DetailGroup",
  components: { ArtistTable, WorkTable, CategoryTable },
  data() {
    return {
      group_form: {
        photo: "",
        name_zh: "",
        name_en: "",
        description_zh: "",
        description_en: "",
      },
      show_detail: false,
      preview_url: "",
      categories: [],
      group_artists: [],
      artists: [],
      works: [],
    };
  },
  computed: {
    groupId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.handleGetGroup();
  },
  methods: {
    async handleGetGroup() {
      const {
        photo,
        group_en,
        group_zh,
        artists,
        works,
        category,
        group_artists,
      } = await getGroup(this.groupId);

      this.preview_url = `${process.env.VUE_APP_IMAGE_API}`.endsWith("/")
        ? `${process.env.VUE_APP_IMAGE_API}${photo}`
        : `${process.env.VUE_APP_IMAGE_API}/${photo}`;
      this.group_form.photo = photo;
      this.group_form.name_en = group_en.name;
      this.group_form.name_zh = group_zh.name;
      this.group_form.description_en = group_en.description;
      this.group_form.description_zh = group_zh.description;

      this.group_artists = group_artists;
      this.artists = artists;
      this.works = works;
      this.categories = category;
    },
    async handleRedirect() {
      await this.$router.push({ name: "Group" });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-group-block {
  ::v-deep.photo-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    &:hover {
      border-color: #409eff;
    }
  }

  ::v-deep.photo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 1200px;
    height: 360px;
    line-height: 360px;
    text-align: center;
  }

  ::v-deep.photo {
    width: auto;
    height: 360px;
    display: block;
  }

  .content {
    margin: 12px 0;
    padding: 24px 0;
    border: 1px solid #606266;
    border-radius: 12px;
    color: var(--primary-gray-color);
    font-size: 14px;
    cursor: pointer;

    &:hover {
      border: 1px solid #409eff;
      color: #409eff;
    }
  }
}
</style>
